import * as THREE from 'three'
import { useRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Image, ScrollControls, Scroll, useScroll } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio'
import { easing } from 'maath'
import Loader from './Loader'
import { Suspense } from 'react'
import { motion, AnimatePresence } from "framer-motion";


const material = new THREE.LineBasicMaterial({ color: 'white' })
const geometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, -0.5, 0), new THREE.Vector3(0, 0.5, 0)])
const state = proxy({
  clicked: null,
  // urls: ['/coins/btc.png', '/coins/eth.png', '/coins/jewel.png', '/coins/rune.png']
  urls: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((u) => `/portfolio/${u}.png`)
  // urls: ['/coins/btc.webp', '/coins/eth.jpeg', '/coins/rune.jpeg', '/coins/jewel.jpeg']

})

function Minimap() {
  const ref = useRef()
  const scroll = useScroll()
  const { urls } = useSnapshot(state)
  const { height } = useThree((state) => state.viewport)
  useFrame((state, delta) => {
    ref.current.children.forEach((child, index) => {
      // Give me a value between 0 and 1
      //   starting at the position of my item
      //   ranging across 4 / total length
      //   make it a sine, so the value goes from 0 to 1 to 0.
      const y = scroll.curve(index / urls.length - 1.5 / urls.length, 4 / urls.length)
      easing.damp(child.scale, 'y', 0.15 + y / 6, 0.15, delta)
    })
  })
  return (
    <group ref={ref}>
      {urls.map((_, i) => (
        <line key={i} geometry={geometry} material={material} position={[i * 0.06 - urls.length * 0.03, -height / 2 + 0.6, 0]} />
      ))}
    </group>
  )
}

function Item({ index, position, scale, c = new THREE.Color(), ...props }) {
  const ref = useRef()
  const scroll = useScroll()
  const { clicked, urls } = useSnapshot(state)
  const [hovered, hover] = useState(false)
  const click = () => (state.clicked = index === clicked ? null : index)
  const over = () => hover(true)
  const out = () => hover(false)
  useFrame((state, delta) => {
    const y = scroll.curve(index / urls.length - 1.5 / urls.length, 4 / urls.length)
    easing.damp3(ref.current.scale, [clicked === index ? 4.7 : scale[0], clicked === index ? 5 : 4 + y, 1], 0.15, delta)
    ref.current.material.scale[0] = ref.current.scale.x
    ref.current.material.scale[1] = ref.current.scale.y
    if (clicked !== null && index < clicked) easing.damp(ref.current.position, 'x', position[0] - 2, 0.15, delta)
    if (clicked !== null && index > clicked) easing.damp(ref.current.position, 'x', position[0] + 2, 0.15, delta)
    if (clicked === null || clicked === index) easing.damp(ref.current.position, 'x', position[0], 0.15, delta)
    easing.damp(ref.current.material, 'grayscale', hovered || clicked === index ? 0 : Math.max(0, 1 - y), 0.15, delta)
    easing.dampC(ref.current.material.color, hovered || clicked === index ? 'white' : '#aaa', hovered ? 0.3 : 0.15, delta)
  })
  return <Image ref={ref} {...props} position={position} scale={scale} onClick={click} onPointerOver={over} onPointerOut={out} />
}

function Items({ w = 0.7, gap = 0.15 }) {
  const { urls } = useSnapshot(state)
  const { width } = useThree((state) => state.viewport)
  const xW = w + gap
  return (
    <ScrollControls horizontal damping={0.1} pages={(width - xW + urls.length * xW) / width}>
      <Minimap />
      <Scroll>
        {urls.map((url, i) => <Item key={i} index={i} position={[i * xW, 0, 0]} scale={[w, 4, 1]} url={url} />) /* prettier-ignore */}
      </Scroll>
    </ScrollControls>
  )
}

export const Portfolio = () => {
  // const [page, setPage] = useState('home')
  // if (page === 'home') {
  //   return <div>
  //     <h1 style={{
  //       background: '-webkit-linear-gradient(rgb(188, 12, 241), rgb(255, 120, 0))',
  //       WebkitBackgroundClip: 'text',
  //       WebkitTextFillColor: 'transparent',
  //       fontSize: '100px',
  //       fontFamily: 'serif',
  //       fontWeight: 'bold',
  //       textAlign: 'center',
  //       marginTop: '100px'
  //     }}>
  //       awkns.
  //     </h1>
  //     <div onClick={() => {
  //       setPage('portfolio')
  //     }} style={{ textAlign: 'center', marginTop: '100px', color: 'white', cursor: 'pointer' }}>Portfolio</div>
  //     <div onClick={() => {
  //       setPage('team')
  //     }} style={{ textAlign: 'center', marginTop: '100px', color: 'white', cursor: 'pointer' }}>Team</div>
  //   </div>
  // } else if (page === 'team') {

  const routeVariants = {
    initial: {
      opacity: 0,
    },
    final: {
      opacity: 1,
    },
  };

  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      exit="exit"
      className="component"
      transition={{ duration: 4 }}
    >
      <Canvas gl={{ antialias: false }} dpr={[1, 1.5]} onPointerMissed={() => (state.clicked = null)}>
        <Suspense fallback={<Loader />}>
          <Items />
        </Suspense>
      </Canvas>
    </motion.div>
  )
  // }
}
